<template>
    <h-row justify="center">
        <h-col cols="11" md="6">
            <h-row justify="center">

                <h-col cols="12">
                    <h-row justify="center">

                        <v-icon style="font-size: 100px;" color="warning">fa-mobile-alt</v-icon>

                    </h-row>
                </h-col>

                <h-col cols="12" class="pb-6">
                    <h-row justify="center">

                        <v-btn x-large
                               width="120"
                               color="warning"
                               :href="'applinks://hand-2-hand.it/app/s/eventi/' + idEvento"
                        >
                            <span>OPEN APP</span>
                        </v-btn>

                    </h-row>
                </h-col>

                <h-col cols="12" class="py-6">
                    <h-row justify="center">
                        <v-divider></v-divider>
                    </h-row>
                </h-col>

                <h-col cols="12" class="pt-6">
                    <h-row justify="center">

                        <v-icon style="font-size: 100px;" color="warning">fa-download</v-icon>

                    </h-row>
                </h-col>

                <h-col cols="12">
                    <h-row justify="center">

                        <v-btn class="mr-4"
                               x-large
                               width="120"
                               color="warning"
                               href="https://play.google.com/store/apps/details?id=com.h2h.it"
                        >
                            <span>ANDROID</span>
                        </v-btn>

                        <v-btn class="ml-4"
                               x-large
                               width="120"
                               color="warning"
                               href="https://apps.apple.com/"
                        >
                            <span>IOS</span>
                        </v-btn>

                    </h-row>
                </h-col>

            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'EventoOpenApp',
        components: {HRow, HCol},
        computed: {
            idEvento: function () {
                return this.$route.params.idEvento;
            }
        }
    }
</script>

<style scoped>

</style>
